/** @jsx jsx */
import React from "react"
import { jsx, Grid } from "theme-ui"

import Img from "../components/image"
import Avatar from "../components/avatar"

type WishGridProps = {
	dataSet: {
		ver: number
		labels: string[]
		names: string[]
	}[]
	folder: string
	star: number
}

const WishGrid = ({dataSet, folder, star}:WishGridProps) => {
	let count = 3
	return (
		<Grid
			gap={0}
			columns={[
				`repeat(`+(dataSet.length+1)+`, 35px)`,
				`repeat(`+(dataSet.length+1)+`, 45px)`,
				`repeat(`+(dataSet.length+1)+`, 55px)`
			]}
			sx={{
				overflowX: `auto`,
				textAlign: `center`,
				mb: 2,
			}}
		>
			<div sx={{gridRow: 1}}></div>
			<div sx={{bg:`bga`, gridRow: 2}}>{`Ver.`}</div>
			{dataSet.map((e,i)=>(<React.Fragment key={i}>
				<div sx={{ gridRow: 1, }}>
					{e.labels.map((name,j)=>(<React.Fragment key={j}>
						{name !== "" && <Img folder={folder} name={name} />}
					</React.Fragment>))}
				</div>
				<div sx={{bg:`bga`, gridRow: 2}}>
					{e.ver.toFixed(1)}
				</div>
			</React.Fragment>))}
			
			{(folder != "weapon" || star != 4) ? <>
				{table(dataSet.map(e => e.names)).map((e,i)=>(<React.Fragment key={i}>
					<div sx={{ gridRow: ++count, }}><Avatar folder={folder} name={e.name} star={star} /></div>
					{e.data.map((num,j)=>(<React.Fragment key={j}>
						{num === -1 ? null
						: num !== 0 ? <div sx={{
							gridRow: count,
							fontFamily: `genshin`,
							fontSize: [3,4,5],
							lineHeight: [`35px`,`45px`,`55px`],
							bg: colorScale((num-1)/20),
						}}>{num}</div>
						: <div sx={{gridRow: count}}>
							<Img folder={folder} name={e.name} />
						</div>}
					</React.Fragment>))}
				</React.Fragment>))}
				<div sx={{bg:`bga`, gridRow: ++count}}>{`Ver.`}</div>
				{dataSet.map((e,i)=>(<React.Fragment key={i}>
					<div sx={{bg:`bga`, gridRow: count}}>
						{e.ver.toFixed(1)}
					</div>
				</React.Fragment>))}
			</> : <>
				{[0,1,2,3,4].map(index=>(<React.Fragment key={index}>
					{table(dataSet.map(e => [e.names[index]])).map((e,i)=>(<React.Fragment key={i}>
						<div sx={{ gridRow: ++count, }}><Avatar folder={folder} name={e.name} star={star} /></div>
						{e.data.map((num,j)=>(<React.Fragment key={j}>
							{num === -1 ? null
							: num !== 0 ? <div sx={{
								gridRow: count,
								fontFamily: `genshin`,
								fontSize: [3,4,5],
								lineHeight: [`35px`,`45px`,`55px`],
								bg: colorScale((num-1)/20),
							}}>{num}</div>
							: <div sx={{gridRow: count}}>
								<Img folder={folder} name={e.name} />
							</div>}
						</React.Fragment>))}
					</React.Fragment>))}
					<div sx={{bg:`bga`, gridRow: ++count}}>{`Ver.`}</div>
					{dataSet.map((e,i)=>(<React.Fragment key={i}>
						<div sx={{bg:`bga`, gridRow: count}}>
							{e.ver.toFixed(1)}
						</div>
					</React.Fragment>))}
				</React.Fragment>))}
			</>}
		</Grid>
	)
}
export default WishGrid

const table = array => {
	const arr = array.concat().reverse()
		, list = []
	for(const names of arr) {
		for(const name of names) {
			if(list.indexOf(name) === -1 && name !== "")
				list.push(name)
		}
	}
	return list.map(e => {
		const tmp = []
		let count = -1
		for(const names of arr) {
			if(names.indexOf(e) !== -1) count = 0	//PUされた
			else if(count !== -1) count++	//PUされない＋過去にPU
			tmp.push(count)
		}
		return {name: e, data: tmp.reverse()}
	}).reverse()
}

const colorScale = value => {
	const a = 0.25
	let r,g,b
	const val = (-Math.cos( 4 * Math.PI * value ) / 2 + 0.5) * 255
		 if(value >= (4/4)) {r=255;	g=0;	b=0;}
	else if(value >= (3/4)) {r=255;	g=val;	b=0;}
	else if(value >= (2/4)) {r=val;	g=255;	b=0;}
	else if(value >= (1/4)) {r=0;	g=255;	b=val;}
	else if(value >= (0/4)) {r=0;	g=val;	b=255;}
	else 					{r=0;	g=0;	b=255;}
	return `rgba(${r},${g},${b},${a})`
}
