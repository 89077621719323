/** @jsx jsx */
import { useState } from "react"
import { jsx, Flex, Checkbox, Divider } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Tab from "../components/tab"
import ExternalLink from "../components/external-link"
import WishGrid from "../components/@wish-banner-grid"

import useWishBanner from "../hooks/use-wish-banner"

const Page = () => {
	const {t} = useTranslation()
		, [check,setCheck] = useState(false)
		, allWish = useWishBanner()
		, characters5 = allWish.filter(e => {
			if(check) return e.character5 != null
			else return (e.character5 !== null && e.character5[0] !== "Keqing")
		})
		, characters4 = allWish.filter(e => e.character5 != null)
		, weapons = allWish.filter(e => e.weapon5 != null)
	return (<Layout title={t(`Wish Banner History`)}>
		<Tab labels={[t(`Character`), t(`Weapon`)]} safety={false}>
			<div>
				<WishGrid dataSet={characters5.map(e => (
					{ver: e.ver, labels: e.character5, names:e.character5}
				))} folder="character" star={5} />
				<Flex sx={{justifyContent: `space-between`,}}>
					<label sx={{display: `flex`,}}>
						<Checkbox onChange={e => setCheck(e.target.checked)} />
						{t(`Include Keqing`)}
					</label>
					<div sx={{display: [`none`,`none`,`block`]}}>{t(`[Shift] + [Mouse Wheel] to scroll horizontally`)}</div>
				</Flex>
				<Divider sx={{my: [3,4]}}/>
				<WishGrid dataSet={characters4.map(e => (
					{ver: e.ver, labels: e.character5, names:e.character4}
				))} folder="character" star={4} />
			</div>
			<div>
				<WishGrid dataSet={weapons.map(e => (
					{ver: e.ver, labels: e.weapon5, names:e.weapon5}
				))} folder="weapon" star={5} />
				<Divider sx={{my: [3,4]}}/>
				<WishGrid dataSet={weapons.map(e => (
					{ver: e.ver, labels: e.weapon5, names:e.weapon4}
				))} folder="weapon" star={4} />
			</div>
		</Tab>
		<h2>{t(`Reference Links`)}</h2>
		<ExternalLink href="https://genshin-impact.fandom.com/wiki/Wishes/History">
			{t(`Wishes/History | Fandom`)}
		</ExternalLink>
	</Layout>)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","wish-banner"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
