import { graphql, useStaticQuery } from "gatsby"

type UseWishBannerProps = {
	allWishBannerJson: {
		nodes: {
			ver: number
			character5: string[]
			character4: string[]
			weapon5: string[]
			weapon4: string[]
		}[]
	}
}

const useWishBanner = () => {
	const data = useStaticQuery<UseWishBannerProps>(graphql`{
		allWishBannerJson {
			nodes {
				ver
				character5
				character4
				weapon5
				weapon4
			}
		}
	}`)
	return data.allWishBannerJson.nodes.filter(e => e.ver != 0)
}

export default useWishBanner
